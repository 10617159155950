import React, { useEffect, useState } from "react";
import "../styles/BookingForm.css";
import building from "../images/building.jpeg";

function BookingForm(props) {
  // Define state variables for each form field
  const [firstName, setFirstName] = useState(props.formData.firstName);
  const [lastName, setLastName] = useState(props.formData.lastName);
  const [email, setEmail] = useState(props.formData.email);
  const [phone, setPhone] = useState(props.formData.phone);
  const [comments, setComments] = useState(props.formData.comments);
  const [instagram, setInstagram] = useState(props.formData.instagram);
  // const [silentTreatment, setSilentTreatment] = useState(props.formData.silentTreatment);
  const [loading, setLoading] = useState(false);
  const [confirmedBooking, setConfirmedBooking] = useState(false);
  const [time, setTime] = useState(props.selectedTimeFromModal);
  const [bookings, setBookings] = useState(null);
  const [lashSets, setLashSets] = useState(null);

  const [timeId, setTimeId] = useState(props.selectedTimeIdFromModal);

  useEffect(() => {
    const updatedData = {
      firstName,
      lastName,
      email,
      phone,
      comments,
      instagram,
    };
    props.handleFormData(updatedData);
  }, [firstName, lastName, email, phone, comments, instagram]);

  useEffect(() => {
    console.log("date " + props.selectedDateFromModal.id);
    console.log("time " + props.selectedTimeFromModal);
  }, []);

  useEffect(() => {
    fetch("https://razwebdev.com/lashes/api/bookings2")
      .then((response) => response.json())
      .then((json) => {
        setBookings(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  useEffect(() => {
    fetch("https://razwebdev.com/lashes/api/lashsets2")
      .then((response) => response.json())
      .then((json) => {
        setLashSets(json.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // const formatSeparateDate = (date) => {
  //   const weekdayOptions = { weekday: 'long' };
  //   const monthOptions = { month: 'long' };
  //   const dayOptions = { day: 'numeric' };

  //   const formattedWeekday = date.toLocaleDateString(undefined, weekdayOptions);
  //   const formattedMonth = date.toLocaleDateString(undefined, monthOptions);
  //   const formattedDay = date.toLocaleDateString(undefined, dayOptions);

  //   return {
  //     weekday: formattedWeekday,
  //     month: formattedMonth,
  //     day: formattedDay,
  //   };
  // };

  // const handleSilentAppointmentChange = (e) => {
  //   const selectedValue = e.target.value;
  //   setSilentTreatment(selectedValue);
  // };

  const emailContent = `
  <!DOCTYPE html>
  <html lang="en">
  <head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Lux Lashes Booking Confirmation</title>
  </head>
  <body style="font-family: Arial, sans-serif; background-color: #f4f4f4; color: black; margin: 0; padding: 0;">
    <div style="max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 20px; border-radius: 5px; box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); text-align: left;">
      <h1 style="color: #333; padding-bottom: 5px; border-bottom: 4px solid #FFB6C1;">Booking Confirmation</h1>
      <div class="booking-info">
        <h3 style="text-align: left; color: #333;">Hi ${firstName} ${lastName},</h3>

        <p style="color: #333;">You're all set ! This is your confirmation of your appointment with LuxLashes on ${
          props.selectedDateFromModal.daytext
        }, ${props.selectedDateFromModal.month} ${
    props.selectedDateFromModal.daynumber
  } at ${props.selectedTimeFromModal}</p>

        <p style="color: #333;"><strong>-Service:</strong> ${
          props.isSelectedService && props.selectedRemoval
            ? `${props.selectedService.name} & ${props.selectedRemovalService.name}`
            : props.isSelectedService
            ? props.selectedService.name
            : props.selectedRemovalService.name
        }</p>

        <p style="color: #333;"><strong>-Duration:</strong> ${
          props.isSelectedService && props.selectedRemoval
            ? parseFloat(props.selectedService.duration) +
              parseInt(props.selectedRemovalService.duration) / 60
            : props.isSelectedService
            ? parseFloat(props.selectedService.duration)
            : parseInt(props.selectedRemovalService.duration)
        }${props.isSelectedService ? " hours" : " minutes"}</p>

        <p style="color: #333;"><strong>-Price:</strong> ${
          props.isSelectedService && props.selectedRemoval
            ? parseInt(props.selectedService.price) +
              parseInt(props.selectedRemovalService.price)
            : props.isSelectedService
            ? parseInt(props.selectedService.price)
            : parseInt(props.selectedRemovalService.price)
        } (cash only please)</p>
                
        <p style="color: #333;"><strong>-Address:</strong> Ubbo Emmiuslaan 323 (Building C)</p>

        <p style="color: #333;">-The building is the third one, the one next to the bridge. To get in, call "323" on the interphone. Then take the elevator to Floor 3, then turn left to Apartment 323. </p>

        <p style="color: #333;"><strong>IMPORTANT:</strong> A free lash bath will be given to you, so please come with no makeup around your eyes, under them or on the eyelids.</p>

        <img src=https://luxlashesleeuwarden.com/building.jpeg alt="building" style="max-width: 100%; height: auto;">

        <p style="color: #333;">Get ready to feel the prettiest and most confident you have ever been. See you soon!</p>
        <span style="color: #333;">With Love, Team LuxLashes </span>
      </div>
    </div>
  </body>
  </html>
`;
  const temporarySubmit = (e) => {
    alert(
      "Hi there! For the next week, booking through the website is not possible. The Christmas time is very crowded, therefore please send a direct message to LuxLashes about your desired spot. (instagram: lux.lashes.leeuwarden)"
    );
  };

  const handleSubmit = (e) => {
    const date =
      props.selectedDateFromModal.daytext +
      " " +
      props.selectedDateFromModal.daynumber +
      " " +
      props.selectedDateFromModal.month;

    var lashSetId;
    if (props.isSelectedService) lashSetId = props.selectedService.id;
    else lashSetId = props.selectedRemovalService.id;
    const lashSet = lashSets.find((set) => set.id === lashSetId);
    console.log(lashSet.category);
    const filteredBookings = bookings
      .filter((booking) => {
        return booking.email.toLowerCase() === email.toLowerCase();
      })
      .sort((a, b) => {
        const dateA = new Date(parseDate(a.date));
        const dateB = new Date(parseDate(b.date));
        return dateA - dateB; // Sort descending
      });

    console.log(filteredBookings);

    const lastNewSet = filteredBookings.filter((booking) => {
      const lashSet = lashSets.find((set) => set.id === booking.lashSetId);
      return (
        lashSet &&
        lashSet.category !== "Infills" &&
        lashSet.name !== "Removal" &&
        booking.status !== "canceled"
      );
    });

    console.log("last new sets");
    console.log(lastNewSet);

    // const lastNewSet = filteredBookings.filter((booking) => {
    //   const lashSet = lashSets.filter((lashSet) => {
    //     return lashSet.id === booking.lashSetId;
    //   });
    //   return lashSet.category != "Infills";
    // });

    if (lastNewSet[lastNewSet.length - 1] != null)
      console.log(
        "last new set date " + lastNewSet[lastNewSet.length - 1].date
      );
    console.log("new sets" + lastNewSet.length);

    const lastAppointment = filteredBookings.filter((booking) => {
      const lashSet = lashSets.filter((lashSet) => {
        return lashSet.id === booking.lashSetId;
      });
      return lashSet.name !== "Removal";
    });
    console.log(lastAppointment[lastAppointment.length - 1]);

    function parseDate(dateStr) {
      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const [dayName, day, month] = dateStr.split(" ");
      const monthIndex = months.indexOf(month);

      // Hardcode the year based on the month
      const year = monthIndex === 0 ? 2025 : 2024; // January -> 2025, others -> 2024

      return new Date(year, monthIndex, parseInt(day, 10));
    }

    function daysBetweenDates(dateString) {
      // Parse the date string to a Date object
      const targetDate = new Date(dateString);
      console.log("Parsed targetDate:", targetDate);

      // Parse the selected date from modal properties
      const targetDate2 = new Date(
        parseDate(
          props.selectedDateFromModal.daytext +
            " " +
            props.selectedDateFromModal.daynumber +
            " " +
            props.selectedDateFromModal.month
        )
      );
      console.log("Parsed targetDate2 from modal properties:", targetDate2);

      // Calculate the difference in time (in milliseconds)
      const diffTime = Math.abs(targetDate2 - targetDate);
      console.log("Difference in milliseconds:", diffTime);

      // Convert milliseconds to days
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      console.log("Difference in days:", diffDays);

      return diffDays;
    }

    const lastPastAppointment = filteredBookings
      .filter((booking) => {
        const bookingDate = parseDate(booking.date);
        return bookingDate <= parseDate(date) && booking.status != "canceled"; // Only consider past appointments
      })
      .sort((a, b) => {
        const dateA = new Date(parseDate(a.date));
        const dateB = new Date(parseDate(b.date));
        return dateB - dateA; // Sort descending
      })[0]; // Get the most recent past appointment

    const daysDiff =
      lastPastAppointment != null && lastPastAppointment.date != null
        ? daysBetweenDates(parseDate(lastPastAppointment.date))
        : 0;

    // const daysDiff =
    //   lastAppointment[lastAppointment.length - 1] != null &&
    //   lastAppointment[lastAppointment.length - 1].date != null
    //     ? daysBetweenDates(
    //         parseDate(lastAppointment[lastAppointment.length - 1].date)
    //       )
    //     : 0;
    console.log("days diff " + daysDiff);
    console.log("last past appointment ");
    console.log(lastPastAppointment);

    // console.log(parseDate(lastAppointment[lastAppointment.length - 1].date));
    // console.log(parseDate(date));
    // console.log(date);

    const bookingsAfterLastNewSet = filteredBookings.filter((booking) => {
      const bookingDate = parseDate(booking.date);
      // if (lastNewSet.date) {

      if (lastNewSet[lastNewSet.length - 1] != null) {
        const lastNewSetDate = parseDate(
          lastNewSet[lastNewSet.length - 1].date
        );

        return bookingDate > lastNewSetDate && booking.status !== "canceled";
      }
    });

    console.log(
      "bookings after last new set " + bookingsAfterLastNewSet.length
    );

    const infillsAfterLastNewSet = bookingsAfterLastNewSet.filter((booking) => {
      const lashSet = lashSets.find((set) => set.id === booking.lashSetId);
      return lashSet && lashSet.category === "Infills";
      // return lashSet;
    });

    console.log("hau bau" + lashSet);
    console.log(lashSet);

    // const infillsAfterLastNewSet = filteredBookings.filter((booking) => {
    //   const lashSet = lashSets.filter((lashSet) => {
    //     return lashSet.id === booking.lashSetId;
    //   });
    //   return lashSet.category === "Infills";
    // });

    console.log("infills after last new set " + infillsAfterLastNewSet.length);

    // e.preventDefault(); // Prevent the default form submission behavior

    // console.log("selected service date" + date);
    // Check if any of the values are null or empty strings
    // Check if any of the values are null or empty strings
    var withRemoval;
    if (props.selectedRemoval) withRemoval = "yes";
    else withRemoval = "no";

    if (
      firstName !== null &&
      firstName.trim() !== "" &&
      lastName !== null &&
      lastName.trim() !== "" &&
      email !== null &&
      email.trim() !== "" &&
      phone !== null &&
      phone.trim() !== "" &&
      lashSetId !== null &&
      lashSetId.trim() !== ""
      // &&
      // silentTreatment !== null && silentTreatment.trim() !== ''
    ) {
      if (lashSet.category === "Infills" && daysDiff > 21) {
        alert(
          "Oops ! It looks like it has been more than 21 days since your last appointment, therefore it is not possible to book an infill anymore. After 21 days, a new set is required."
        );
      } else if (
        infillsAfterLastNewSet.length >= 2 &&
        lashSet.category === "Infills"
      ) {
        e.preventDefault();
        alert(
          "Oops ! It looks like you already had 2 infills on this set. After 2 infills, a new set is required. (Removal too, if needed)"
        );
      } else {
        e.preventDefault();
        console.log("yes can book");
        setLoading(true);

        const formData = new FormData();
        formData.append("firstName", firstName);
        formData.append("lastName", lastName);
        formData.append("email", email);
        formData.append("phone", phone);
        formData.append("date", date);
        formData.append("time", time);
        formData.append("removal", withRemoval);
        formData.append("status", "pending");
        formData.append("comments", comments);
        formData.append("instagram", instagram);
        formData.append("lashSetId", lashSetId);
        // formData.append("silentTreatment", silentTreatment);
        formData.append("emailContent", emailContent);

        // Make a POST request using the fetch API
        fetch("https://razwebdev.com/lashes/api/insertbooking2", {
          method: "POST",
          body: formData,
        })
          .then((response) => response.text())
          .then((json) => {
            setLoading(false);
            setConfirmedBooking(true);
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setTime("");
            setComments("");
            // setSilentTreatment("");
            setInstagram("");
            props.checkIfBookingConfirmed(true);
            console.log(json);
            const formData2 = new FormData();
            formData2.append("id", timeId);
            formData2.append("date", props.selectedDateFromModal.id);
            // console.log(props.selectedDateFromModal.id)

            fetch("https://razwebdev.com/lashes/api/updatetimeslot", {
              method: "POST",
              body: formData2,
            })
              .then((response) => response.text())
              .then((json) => {
                console.log(json);
                props.refreshTimeSlots();
              })
              .catch((e) => {
                console.log(e.message);
              });
          })
          .catch((e) => {
            console.log(e.message);
          });
      }
    }
  };

  return (
    <div>
      {!confirmedBooking && <p className="details-p">Your Details </p>}
      {/* {props.selectedRemoval&&<h1>yes removal</h1>}
      {!props.selectedRemoval&&<h1>no removal</h1>}
      {!props.isSelectedService && <p>No service</p>} */}

      {loading && <p>Loading....</p>}
      {!loading && !confirmedBooking && (
        <form className="booking-form">
          <div className="form-div">
            <div className="name-fields">
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                  // handleFieldChange('First Name', e.target.value);
                }}
                required
              />
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={lastName}
                onChange={(e) => {
                  setLastName(e.target.value);
                  // handleFieldChange('Last Name', e.target.value);
                }}
                required
              />
            </div>
            <input
              type="email"
              id="email"
              name="email"
              placeholder="Email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                // handleFieldChange('Email', e.target.value);
              }}
              required
            />
            <input
              type="tel"
              id="phone"
              name="phone"
              placeholder="Phone"
              value={phone}
              onChange={(e) => {
                setPhone(e.target.value);
                // handleFieldChange('Phone', e.target.value);
              }}
              required
            />
            <textarea
              id="comments"
              name="comments"
              placeholder="Comments"
              value={comments}
              onChange={(e) => {
                setComments(e.target.value);
                // handleFieldChange('Comments', e.target.value);
              }}
              required
            />
            <input
              type="text"
              id="instagram"
              name="instagram"
              placeholder="Instagram(optional)"
              value={instagram}
              onChange={(e) => {
                setInstagram(e.target.value);
                // handleFieldChange('Last Name', e.target.value);
              }}
            />

            <div className="lash-set-info">
              <span
                style={{
                  marginLeft: "0.4rem",
                  fontSize: "1.2rem",
                  fontWeight: "600",
                }}
              >
                {props.selectedDateFromModal.daytext},{" "}
                {props.selectedDateFromModal.daynumber}{" "}
                {props.selectedDateFromModal.month} at{" "}
                {props.selectedTimeFromModal}
              </span>
              {props.selectedRemoval && props.isSelectedService && (
                <p className="details-p-booking">
                  Total Duration:{" "}
                  {parseFloat(props.selectedService.duration) +
                    parseInt(props.selectedRemovalService.duration) / 60}{" "}
                  hours
                </p>
              )}

              {props.selectedRemoval && !props.isSelectedService && (
                <p className="details-p-booking">
                  Total Duration:{" "}
                  {parseInt(props.selectedRemovalService.duration)} minutes
                </p>
              )}

              {!props.selectedRemoval && (
                <p className="details-p-booking">
                  Total Duration: {props.selectedService.duration} hours
                </p>
              )}

              {!props.selectedRemoval && (
                <p className="details-p-booking-name">
                  {props.selectedService.name}
                </p>
              )}
              {props.selectedRemoval && props.isSelectedService && (
                <div>
                  <p className="details-p-booking-name">
                    {props.selectedService.name}
                  </p>
                  <p className="details-p-booking-name">
                    {props.selectedRemovalService.name}
                  </p>
                </div>
              )}
              {props.selectedRemoval && !props.isSelectedService && (
                <div>
                  <p className="details-p-booking-name">
                    {props.selectedRemovalService.name}
                  </p>
                </div>
              )}
              <div className="details-p-booking-total">
                <p>Total</p>
                {!props.selectedRemoval && (
                  <p>{props.selectedService.price} €</p>
                )}
                {props.selectedRemoval && props.isSelectedService && (
                  <p>
                    {parseInt(props.selectedService.price) +
                      parseInt(props.selectedRemovalService.price)}{" "}
                    €
                  </p>
                )}
                {props.selectedRemoval && !props.isSelectedService && (
                  <p>{parseInt(props.selectedRemovalService.price)} €</p>
                )}
              </div>
            </div>
            {/* <div>
              Please don't hesitate to ask for a silent appointment if you're anxious, tired, mentally drained, etc.
              and just want to avoid conversation and have some quiet time or just listen to music.
              There will be no judgment... there never is. Regardless of the reason, you are always welcome to utilize this option.
            </div> */}
            {/* <div>
              <select
                id="silentAppointment"
                name="silentAppointment"
                value={silentTreatment}
                onChange={handleSilentAppointmentChange}
                required
              >
                <option value="" disabled selected hidden>Choose your preference</option>
                <option value="yes">Yes, I would like a silent appointment</option>
                <option value="no">No, I do not want a silent appointment</option>
                <option value="not_sure">I am not sure yet</option>
              </select>
            </div> */}
          </div>
          <button className="confirm-button" onClick={handleSubmit}>
            {/* <button className="confirm-button" onClick={temporarySubmit}> */}
            Confirm Booking
          </button>
        </form>
      )}

      {confirmedBooking && (
        <div>
          <div
            style={{
              padding: "10px",
              paddingBottom: "1rem",
              paddingTop: "3rem",
            }}
          >
            <h4>Congratulations, your booking has been confirmed</h4>
            {/* <p> Address: Ubbo Emmiuslaan 323</p> */}
            <p>
              {" "}
              Please check your email for the booking confirmation containing
              the address and all the preparation details
            </p>
            <p>
              {" "}
              If you do not receive a confirmation email in the next 1-2
              minutes, please contact LuxLashes directly
            </p>
          </div>
          <div className="lash-set-info">
            <span
              style={{
                marginLeft: "0.4rem",
                fontSize: "1.2rem",
                fontWeight: "600",
              }}
            >
              {props.selectedDateFromModal.daytext},{" "}
              {props.selectedDateFromModal.daynumber}{" "}
              {props.selectedDateFromModal.month} at{" "}
              {props.selectedTimeFromModal}
            </span>
            {props.selectedRemoval && props.isSelectedService && (
              <p className="details-p-booking">
                Total Duration:{" "}
                {parseFloat(props.selectedService.duration) +
                  parseInt(props.selectedRemovalService.duration) / 60}{" "}
                hours
              </p>
            )}

            {props.selectedRemoval && !props.isSelectedService && (
              <p className="details-p-booking">
                Total Duration:{" "}
                {parseInt(props.selectedRemovalService.duration)} minutes
              </p>
            )}

            {!props.selectedRemoval && (
              <p className="details-p-booking">
                Total Duration: {props.selectedService.duration} hours
              </p>
            )}

            {props.selectedRemoval && props.isSelectedService && (
              <div>
                <p className="details-p-booking-name">
                  {props.selectedService.name}
                </p>
                <p className="details-p-booking-name">
                  {props.selectedRemovalService.name}
                </p>
              </div>
            )}

            {props.selectedRemoval && !props.isSelectedService && (
              <div>
                <p className="details-p-booking-name">
                  {props.selectedRemovalService.name}
                </p>
              </div>
            )}

            {!props.selectedRemoval && (
              <div>
                <p className="details-p-booking-name">
                  {props.selectedService.name}
                </p>
              </div>
            )}
            <div className="details-p-booking-total">
              <p>Total</p>
              {!props.selectedRemoval && <p>{props.selectedService.price} €</p>}
              {props.selectedRemoval && props.isSelectedService && (
                <p>
                  {parseInt(props.selectedService.price) +
                    parseInt(props.selectedRemovalService.price)}{" "}
                  €
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default BookingForm;
